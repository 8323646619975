import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Box, Title, Button } from "../../components/Core";
import IconCircle from "../../components/IconCircle";

const Contact = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <Section hero className="position-relative">
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="10">
              <Box className="text-center d-flex flex-c justify-content-center align-items-center">
                <IconCircle bg="#F5E9B4" color="dark">
                  <i className="icon icon-chat-round-2"></i>
                </IconCircle>
              </Box>
              <div className="text-center my-5">
                <Title variant="secSm">
                  Find yourself always rehiring agencies? Look no further.
                </Title>
              </div>
              <div className="text-center">
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Send us a message
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
