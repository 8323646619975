import React from "react";
import { Element } from "react-scroll";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/propellant/Hero";
import Pitch from "../sections/propellant/Pitch";
import Contact from "../sections/propellant/Contact";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Element name="pitch">
          <Pitch />
        </Element>
        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
