import React from "react";
import styled from "styled-components";
import Lottie from 'react-lottie'
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import editingAnimation from "../../assets/animations/editing.json"
import technologyLogos from "../../assets/images/agency/technology-logos.png"

const Image = styled.img`
  max-width: 80%;
  margin-top: 40px;

  @media ${device.sm} {
    margin-top: 60px;
    max-width: 60%;
  }
  @media ${device.lg} {
    margin-top: 0;
    max-width: 80%;
  }
`;

const Animation = styled.div`
  margin-left: -70px;
`

const Pitch = () => {
  return (
    <>
     <Section hero>
        <Container>
          <Row className="justify-content-between align-items-center text-center text-lg-left">
            <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Box>
                <Animation>
                  <Lottie
                    options={{
                      animationData: editingAnimation,
                      loop: true,
                      autoplay: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }} />
                </Animation>
              </Box>
            </Col>
            <Col lg={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
              <Box>
                <Title variant="secSm">Tailored for you</Title>
                <Text pb="0" pt="1.25rem" color="heading">
                  Whether you need a concrete product deliverable, technical consultancy, or an on-premise engineer, we can do it all.
                  Unlike other agencies, we will carefully understand your goals to provide an offering that best fit your needs.
                </Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section hero>
        <Container>
          <Row className="justify-content-between align-items-center text-center text-lg-left">
            <Col lg="6">
              <Box>
                <Title variant="secSm">Future proofing</Title>
                <Text pb="0" pt="1.25rem" color="heading">
                  At Propellant Software, our goal is to set you up for success.
                  Our engineering team will dedicate themselves to working on your product or project with only the the latest proven technologies that were made to scale.
                </Text>
              </Box>
            </Col>
            <Col xl="5" lg="6">
              <Box>
                <div className="text-center text-lg-right position-relative">
                  <div className="img-main">
                    <Image src={technologyLogos} alt="tecnologies" />
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Pitch;
